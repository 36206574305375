import { forwardRef } from 'react';

const RichText = forwardRef(({ text, className }, ref) => (
  <div
    ref={ref}
    className={className}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: text.replaceAll('\r', '<br>').replaceAll('\n', '<br>'),
    }}
  />
));

RichText.displayName = 'RichText';

export default RichText;
