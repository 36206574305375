import PropTypes from 'prop-types';

function FormRow({ id, label, required, children, hideLabel = false }) {
  return (
    <div className="c-form-row">
      <label
        className={`c-form__label ${hideLabel ? ' u-visually-hidden' : ''}`}
        htmlFor={id}
      >
        <span className="c-form__label-inner">
          {`${label}`}
          {required && '*'}
        </span>
      </label>
      <div className="c-form__input-container">{children}</div>
    </div>
  );
}

FormRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

export default FormRow;
