import { useState, useEffect } from 'react';
import { withTranslation } from 'next-i18next';
import { Wrapper } from '@googlemaps/react-wrapper';
import SearchIcon from 'svg/search.svg';
import PropTypes from 'prop-types';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import { getHighlightedText } from 'utils';

// eslint-disable-next-line react/jsx-props-no-spreading
const DropdownIndicator = ({ innerProps }) => <SearchIcon {...innerProps} />;

const PlaceSearch = ({
  t,
  onChange,
  value,
  label,
  placeholder,
  hideLabel = false,
  className,
}) => {
  const [inputValue, setInputValue] = useState(value || '');

  const handleChange = data => {
    if (data?.value) {
      geocodeByPlaceId(data.value.place_id)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          const place = data.value.description;

          onChange({ place, lat, lng });
        })
        .catch(error => console.error(error));
    } else {
      onChange({ place: '', lat: '', lng: '' });
    }
  };

  const setNoOptionsMessage = () => {
    if (!inputValue) return t('autoComplete.noOptionsAvailable');
    return t('autoComplete.noOptionsAvailableFor').replace(
      '%value%',
      inputValue
    );
  };

  const onInputChange = (newValue, options) => {
    if (
      ['input-blur', 'menu-close'].includes(options.action) &&
      inputValue !== ''
    )
      return;
    setInputValue(newValue);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <label
        className={`c-form__label ${hideLabel ? 'u-visually-hidden' : ''}`}
        htmlFor="place-search"
      >
        <span className="c-form__label-inner">{label}</span>
      </label>
      <Wrapper
        apiKey={process.env.GOOGLE_API_KEY}
        libraries={['places']}
        render={() => (
          <input
            className="c-input-text c-input-text--autocomplete-placeholder"
            disabled
          />
        )}
      >
        <GooglePlacesAutocomplete
          selectProps={{
            inputId: 'place-search',
            inputValue,
            classNamePrefix: 'c-autocomplete',
            className: `c-autocomplete c-autocomplete--place-search ${
              className || ''
            }`,
            onChange: newValue => handleChange(newValue),
            onInputChange,
            isClearable: true,
            placeholder,
            noOptionsMessage: options => setNoOptionsMessage(options),
            loadingMessage: () => t('loading.label'),
            components: {
              DropdownIndicator,
            },
            formatOptionLabel: ({ label: optionLabel }, { inputValue: iv }) =>
              getHighlightedText(optionLabel, iv),
          }}
          minLengthAutocomplete={3}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['nl', 'be'],
            },
          }}
          debounce={350}
          withSessionToken
        />
      </Wrapper>
    </>
  );
};

DropdownIndicator.propTypes = {
  innerProps: PropTypes.object,
};

PlaceSearch.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
};

export default withTranslation()(PlaceSearch);
