import PropTypes from 'prop-types';

import Button from 'components/Button';

export default function FullBannerSection({
  title,
  cta,
  background = 'default',
}) {
  return (
    <section className={`c-full-banner c-full-banner--bg-${background}`}>
      <div className="o-retain o-retain--wall c-full-banner__container">
        <h2 className="c-full-banner__title">{title}</h2>
        <Button
          extraClasses="c-button--secondary"
          label={cta.label}
          href={cta.href}
        />
      </div>
    </section>
  );
}

FullBannerSection.propTypes = {
  title: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
  background: PropTypes.oneOf(['default', 'light']),
};
