import RichText from '@atoms/RichText/RichText';
import FilterForm from '@molecules/FilterForm/FilterForm';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import HeroVisual from 'svg/hero-visual.svg';

export default function FilterHero({
  title,
  text,
  cta,
  variant = 'home',
  formTitle,
}) {
  const showForm = ['home', 'vacancies'].includes(variant);

  return (
    <section className={`c-filter-hero c-filter-hero--${variant}`}>
      <div className="c-filter-hero__container o-retain o-retain--wall">
        <HeroVisual className="c-filter-hero__visual" />

        <div className="c-filter-hero__content">
          <h1
            className="c-filter-hero__title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {text && <RichText text={text} className="c-filter-hero__text" />}
          {cta && (
            <Button
              extraClasses="c-button--secondary"
              label={cta.label}
              IconLeft={cta.icon}
              Tag="a"
              href={cta.href}
            />
          )}
        </div>

        {showForm && (
          <div className="c-filter-hero__form-container">
            <FilterForm
              title={formTitle}
              autoCompleteLabel="Functie, trefwoord of bedrijfsnaam"
              autoCompletePlaceholder="Functie, trefwoord of bedrijfsnaam"
              placeLabel="Plaats of postcode"
              placePlaceholder="Plaats of postcode"
              submitLabel="Vacatures zoeken"
              homepageFilter
            />
          </div>
        )}
      </div>
    </section>
  );
}

FilterHero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  formTitle: PropTypes.string,
  variant: PropTypes.oneOf(['home', 'vacancies', 'employers']),
  // Define the prop types this component should receive
  cta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    icon: PropTypes.func,
  }),
};
