import RichText from '@atoms/RichText/RichText';
import Button from 'components/Button';
import Image from 'next/image';
import PropTypes from 'prop-types';
import LogoIcon from 'svg/logo-icon.svg';

function ContentGridItem({ item }) {
  if (item.variant === 'quote') {
    return (
      <blockquote className="c-content-grid__item c-content-grid__item--quote">
        <div className="c-content-grid__item-image-quote">
          <Image
            className="c-content-grid__item-image"
            src={item.image.src}
            alt={item.image.alt}
            width={item.image.width}
            height={item.image.height}
          />
        </div>
        <RichText className="c-content-grid__quote" text={item.text} />
        <cite className="c-content-grid__cite">{item.title}</cite>
      </blockquote>
    );
  }

  return (
    <div className="c-content-grid__item">
      <div className="c-content-grid__item-image-container">
        <Image
          className="c-content-grid__item-image"
          src={item.image.src}
          alt={item.image.alt}
          width={item.image.width}
          height={item.image.height}
        />
      </div>
      <div className="c-content-grid__item-content">
        <h3 className="c-content-grid__item-title">{item.title}</h3>
        <RichText className="c-content-grid__item-text" text={item.text} />
        {!!item.cta && (
          <Button
            label={item.cta.label}
            href={item.cta.url}
            extraClasses="c-button--link c-content-grid__item-cta"
          />
        )}
        {item.smallImage && (
          <Image
            className="c-content-grid__item-small-image"
            src={item.smallImage.src}
            alt={item.smallImage.alt}
            width={item.smallImage.width}
            height={item.smallImage.height}
            quality={100}
          />
        )}
      </div>
    </div>
  );
}

export default function ContentGrid({
  background = 'default',
  withTopPadding = false,
  flipped = false,
  items,
  label,
  title,
  text,
  cta,
}) {
  const hasContent = title || text || cta;

  return (
    <section
      className={`c-content-grid c-content-grid--bg-${background} ${
        flipped ? 'c-content-grid--flipped' : ''
      } ${withTopPadding ? 'c-content-grid--with-top-padding' : ''}`}
    >
      <LogoIcon className="c-content-grid__background-image" />

      {!!hasContent && (
        <div className="o-retain o-retain--wall c-content-grid__container">
          <div className="c-content-grid__content">
            {label && <p className="c-content-grid__label">{label}</p>}
            {title && <h2 className="c-content-grid__title">{title}</h2>}
            {text && <p className="c-content-grid__text">{text}</p>}
            {cta && (
              <Button
                label={cta.label}
                href={cta.url}
                extraClasses="c-button--secondary"
              />
            )}
          </div>
        </div>
      )}

      <div className="o-retain o-retain--wall c-content-grid__items">
        {items.map(item => (
          <ContentGridItem key={item.id} item={item} />
        ))}
      </div>
    </section>
  );
}

ContentGrid.propTypes = {
  flipped: PropTypes.bool,
  background: PropTypes.oneOf(['default', 'light']),
  withTopPadding: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      text: PropTypes.string,
      cta: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
      }),
      // image is an png import for next js image
      image: PropTypes.shape({
        src: PropTypes.object,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      variant: PropTypes.oneOf(['default', 'quote']),
    })
  ),
};
