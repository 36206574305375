import Button from 'components/Button';
import PlaceSearch from '@molecules/FilterHeader/PlaceSearch';
import Autocomplete from '@molecules/FilterHeader/Autocomplete';
import { useFilterHeader } from 'utils';
import VacancySearchIcon from 'svg/vacancy-search.svg';
import LogoIcon from 'svg/logo-icon.svg';
import Link from 'next/link';

export default function FilterForm({
  homepageFilter,
  autoCompleteIndex = 'vacancy',
  hidePlaceInput = false,
  autoCompleteLabel,
  autoCompletePlaceholder,
  placeLabel,
  placePlaceholder,
  submitLabel,
  title,
}) {
  const {
    inputs,
    handleChange,
    onSelect,
    clearInput,
    location,
    setLocation,
    autocompleteSuggestions,
    loading,
    handleSubmit,
  } = useFilterHeader({ homepageFilter, autoCompleteIndex });

  return (
    <form
      className="c-filter-form"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {!!title && (
        <h2 className="c-filter-form__title">
          <VacancySearchIcon />
          {title}
        </h2>
      )}
      <div className="o-layout o-layout--gutter-small o-layout--align-bottom">
        <div className="o-layout__cell u-margin-bottom-small u-margin-bottom-base@from-md">
          <Autocomplete
            className="c-filter-form__autocomplete"
            id="autocomplete"
            name="autocomplete"
            label={autoCompleteLabel}
            hideLabel
            placeholder={autoCompletePlaceholder}
            value={inputs.autocomplete}
            loading={loading}
            onChange={handleChange}
            onSelect={onSelect}
            clearInput={clearInput}
            autocompleteSuggestions={autocompleteSuggestions}
            handleSubmit={handleSubmit}
            disableAutoSubmit={homepageFilter}
          />
        </div>

        {!hidePlaceInput && (
          <div className="o-layout__cell u-margin-bottom-small u-margin-bottom-base@from-md">
            <PlaceSearch
              hideLabel
              label={placeLabel}
              placeholder={placePlaceholder}
              value={location.place}
              onChange={setLocation}
              className="c-filter-form__place-search"
            />
          </div>
        )}

        <div className="o-layout__cell o-layout__cell--fit@from-sm u-margin-left-auto@until-sm c-filter-form__cta-container">
          <Button
            type="submit"
            label={submitLabel}
            Icon={LogoIcon}
            extraClasses="c-button--tertiary c-search-card__button u-margin-top-small@until-md"
            test="submit-filter"
            rawLabel
          />
          <Link
            className="c-filter-form__vacancy-link u-margin-top-small"
            href="/bladeren"
            label="Door alle vactures bladeren"
          >
            Door alle vactures bladeren
          </Link>
        </div>
      </div>
    </form>
  );
}
